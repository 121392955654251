<template>
<div class="container-fluid">
      <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>

            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                    <div class="col-md-4">
                    <div class="input-group no-border">
                       <PerPage id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>

                        <!-- <input type="text" v-model="filter.name" class="form-control" placeholder="بحث">
                        <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button> -->
                    </div>
                    </div>
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                        
                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة الحالات</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          اسم الحالة بالعربي
                        </th>
                        <th>
                          اسم الحالة بالانجليزي
                        </th>

                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(status,index) in StatusList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{status.name.ar}}
                          </td>
                          <td>
                            {{status.name.en}}
                          </td>

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.status.edit', params: {id: status.id}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="StatusList.meta.last_page > 1" :pagination="StatusList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../../components/Pagination.vue'
import PerPage from '../../../../components/PerPage'

export default {
  data(){
   return{
   filter:{
        page:1,
        paginate:1,
        limit:'100',
    },  
     AlertTitle:'',
     AlertMessage:'',
   }
  },
  computed:{
    ...mapState({

        StatusList:state=>state.admin.status.StatusList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchStatusList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
 
 
   },
      components:{
       Pagination,
       PerPage,

   },

  beforeRouteEnter(to,from,next){     
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchStatusList',{page:1,search:'',paginate:1,limit:100}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next();  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>